let inFormOrLink = false;
let refreshKeyPressed = false;
let cntrlIsPressed = false;
const f5key = 116;
const rkey = 82;
const ctrlKey = 17;

//$(document).on('click', 'a', function() { inFormOrLink = true; });
const forms = document.getElementsByTagName('form');
for (i = 0; i < forms.length; i++){
  forms[i].addEventListener('submit', () => inFormOrLink = true);
}

document.addEventListener('keydown', function(evt) {
  if (evt.which === f5key || window.modifierPressed && evt.which === rkey) {
    refreshKeyPressed = true;
  }
  if(evt.which === ctrlKey){
    cntrlIsPressed = true;
  }
});

document.addEventListener('keyup', (evt) => {
  if (evt.which === f5key || evt.which === rkey) {
    refreshKeyPressed = false;
  }
  cntrlIsPressed = false;
});

window.addEventListener('beforeunload', () => {
  if ((!inFormOrLink && !refreshKeyPressed) || (cntrlIsPressed && !refreshKeyPressed && !inFormOrLink)) {
    const href = document.location.href;
    const lastPathSegment = href.substr(href.lastIndexOf('/') + 1);

    if (window.ga) {
      ga('send', 'event', {
        eventCategory: "Browser or Tab Close",
        eventLabel: lastPathSegment,
        eventAction: "Close",
        eventValue: 1
      });
    }
    console.log("Browser/Tab Close");
    cntrlIsPressed = false;
  }
});
